<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">{{$t("Reset_Password")}}</h4>
                                    <p>{{$t("Please_enter_your_new_password")}}.</p>
                                </div>
                                <vs-input
                                    ref="password"
                                    type="password"
                                    data-vv-validate-on="blur"
                                    v-validate="'required|min:8|max:15'"
                                    name="password"
                                    label-placeholder="Password"
                                    :placeholder="$t('Mot_de_passe')"
                                    v-model="password"
                                    class="w-full mt-8" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                                
                                <vs-input
                                    type="password"
                                    v-validate="'min:8|max:15|confirmed:password'"
                                    data-vv-validate-on="blur"
                                    data-vv-as="password"
                                    name="confirm_password"
                                    label-placeholder="Confirm Password"
                                    :placeholder="$t('Confirmation_mot_de_passe')"
                                    v-model="confirm_password"
                                    class="w-full mt-8" />
                                <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>            
                                <br>
                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
                                    <vs-button type="border" to="/pages/login" class="w-full sm:w-auto mb-2 sm:mb-auto mt-8 sm:mt-auto">{{ $t("Connexion") }}</vs-button>
                                    <vs-button @click="ChangePassword" :disabled="!validateForm" class="w-full sm:w-auto">{{$t("Reset")}}</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { Validator } from 'vee-validate'
import router from '../../router'
const dict = {
  custom: {
    password: {
      required: 'Le champ mot de passe est obligatoire',
      min: 'Au minimum 8 caractères.',
      max: 'Au maximum 15 caractères.'
    },
    confirm_password: {
      required: 'Le champ confirmation mot de passe est obligatoire',
      min: 'Au minimum 8 caractères.',
      max: 'Au maximum 15 caractères.',
      confirmed:'La confirmation doit être identique au mot de passe saisie '
    }
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      password: '',
      confirm_password: '',
      token:'',
      uid:''
    }
  },
  computed:{
    validateForm () {
      return !this.errors.any() && this.password !== '' && this.confirm_password !== ''
    }

  },
  methods: {
    async ChangePassword () {
      if (!this.validateForm) {
        this.$vs.notify({
          time: 5000,
          title: 'Erreur',
          text: 'Veuillez remplir tous les champs correctement.',
          color: 'warning',
          position: 'top-center'
        })
        return
      }

      this.$vs.loading()

      try {
        delete this.$http.defaults.headers.common['Authorization']

        const response = await this.$http.post(
          `password-reset/confirm/?token=${this.token}&uid=${this.uid}`,
          { new_password: this.password }
        )

        this.$vs.notify({
          time: 8000,
          title: 'Mot de passe réinitialisé',
          text: response.data.detail || 'Votre mot de passe a été changé avec succès.',
          color: 'success',
          position: 'top-center'
        })

        router.push('/pages/login')
      } catch (error) {
        this.$vs.notify({
          time: 10000,
          title: 'Échec de la réinitialisation',
          text: error.response.data.detail || 'Une erreur est survenue.',
          color: 'danger',
          position: 'top-center'
        })
      } finally {
        this.$vs.loading.close() // Désactivation du chargement
      }
    }
  },
  created () {
    this.token = this.$route.query.token || ''
    this.uid = this.$route.query.uid || ''
  }
}
</script>
